<template>
    <div class="dashboard-container" v-loading="loading">
      <!-- 添加或编辑类别 -->
      <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
        <!-- 表单 -->
        <el-form ref="perForm" :model="userInfoDto" :rules="rules" label-width="120px">
      
          <el-form-item label="用户姓名" prop="username">
            <el-input v-model="userInfoDto.username" style="width: 50%" />
          </el-form-item>
  
          <el-form-item label="登录账号" prop="account">
            <el-input v-model="userInfoDto.account" style="width: 50%" />
          </el-form-item>
        </el-form>
        <el-row slot="footer" type="flex" justify="center">
          <el-col :span="6">
            <el-button size="small" type="primary" @click="btnOK">
              确定
            </el-button>
            <el-button size="small" @click="btnCancel">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
  
      <!-- -------万恶分割线------- -->
  
      <div class="app-container" style="margin-bottom: 20px">
        <el-row >
          
           
            <!-- 用户名 -->
  
          <el-col  align="left" :span="12">
            <el-button size="mini"   type="primary" @click="goback" style="margin-right:50px"
              >返回</el-button
            >
            <el-input placeholder="请输入用户名称" v-model="queryParams.username" size="mini" clearable  style="width:200px"
            ></el-input>
  
            <!-- 手机号 -->
            <el-input
            placeholder="请输入手机号"
            v-model.trim="queryParams.phone"
            size="mini"
            style="width:200px"
            clearable
         
           
          ></el-input>
          <el-button type="info"  size="small" class="btn" @click="searchuser" style="margin-left:20px">查询</el-button>
          </el-col>
          <el-col align="right" :span="12">
          
         
          
         <el-button type="primary" size="small" @click="adduser">添加</el-button>
          </el-col>
        
        </el-row>
      </div>
  
      <!-- 主体内容 -->
  
      <div class="tab">
        <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
          ref="list" class="emp_table" v-if="list">
  
          <el-table-column
            align="center"
            prop="companyName"
            label="企业名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column align="center" prop="username" label="用户名" :show-overflow-tooltip="true" />
          <el-table-column align="center" prop="account" label="账号" :show-overflow-tooltip="true" />
     
          <el-table-column
            align="center"
            prop="phone"
            label="联系电话"
            :show-overflow-tooltip="true"
          />
          <el-table-column align="center" prop="role" label="角色" width="200">
            <template slot-scope="scope">
              {{ scope.row.role | jiaose }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="accountStatus" label="账号状态" width="200">
            <template slot-scope="scope">
              {{ scope.row.accountStatus == 0 ? "启用" : "禁用" }}
            </template>
          </el-table-column>
    
  
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="updateManagerUser(scope.row)">
                编辑
              </el-button>
              <el-button type="text" size="small" @click="sz(scope.row)">
                {{ scope.row.accountStatus == 1 ? "启用" : "禁用" }}
              </el-button>
              <el-button type="text" size="small" @click="deluser(scope.row)">
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize"
          @pagination="getuserlist" />
      </div>
    </div>
  </template>
    
  <script>
  import {
 
    setuser,
    getuserlist,
    deluser,
    adduser,
    updateuser,
  } from "../../request/http";
  
  export default {
    data() {
      return {
        loading: true,//加载动画
        title: "",
        showDialog: false,
        list: "",
        total: 0,
        queryParams: {
          pageNum: 1,
          pageSize: 10,
          username: "",
          phone: "",
          companyId:'',
          role:2
        },
    
  
        dialogVisible: false, //显示添加或编辑框
        userInfoDto: {
          username: "",
          account: "",
          areaId: "",
          companyId: "",
          role: 2
        },
  
        rules: {
          username: [
            { required: true, message: "用户名称不能为空", trigger: "blur" },
          ],
          // areaId: [
          //   { required: true, message: "地区不能为空", trigger: "blur" },
          // ],
        //   companyId: [
        //     { required: true, message: "企业不能为空", trigger: "blur" },
        //   ],
        //   role: [
        //     { required: true, message: "角色不能为空", trigger: "blur" },
        //   ],
          account: [
            { required: true, message: "账号不能为空", trigger: "blur" },
            {
              pattern:
                /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
              message: "请输入正确的手机号码",
            },
          ],
        },
      };
    },
  
    created() {
        this.userInfoDto.companyId = this.$route.query.companyId
        this.queryParams.companyId=this.$route.query.companyId
      this.getuserlist();
    
    },
  
    mounted() { },
    methods: {
        goback() {
      this.$router.go(-1)
    },
     //查询
      async searchuser(){
        this.queryParams.pageNum=1
        console.log(this.queryParams);
        const { data } = await getuserlist(this.queryParams);
        let list = data.data;
        console.log(list);
        if (data.code !== 0) {
          return this.$message.error("获取数据失败");
        } 
        this.total = list.total;
        this.list =  list.records;
        this.loading = false
      },
       //获取列表
       async getuserlist() {
       
        if (this.queryParams.username=="" &&this.queryParams.phone=="") {
          console.log(this.queryParams,'无参数');
          let { data } = await getuserlist(this.queryParams);
        let list = data.data;
        console.log(list);
        this.list = list.records;
  
        this.total = list.total;
        this.loading = false
       
         
        }
        else {
      
        console.log(this.queryParams ,'有参数');
        let { data } = await getuserlist(this.queryParams);
        let list = data.data;
        console.log(list);
        this.list = list.records;
  
        this.total = list.total;
        this.loading = false
       
        }
       
      },
    
    
    
  
  
   
  
  
      async sz(row) {
        let id = row.userId;
        let { data } = await setuser(id);
        data.code == 0 &&
          this.$message({
            type: "success",
            message: "设置成功",
          });
        this.getuserlist();
      },
     
  
      // 打开添加的窗口
      adduser() {
        this.title = "添加用户信息";
       this.userInfoDto= {
          username: "",
          account: "",
          areaId: "",
          companyId: this.$route.query.companyId,
          role: 2
        },
        this.showDialog = true;
      },
      // 打开编辑类别的窗口
      updateManagerUser(val) {
        // 根据获取id获取详情
        this.title = "编辑用户信息";
  
        console.log(val);
        this.userInfoDto = {
          userId: val.userId,
          username: val.username,
          account: val.account,
          companyId: String(val.companyId),
          role: val.role,
          areaId: val.areaId,
  
        };
        this.showDialog = true;
      },
      // 确认
      async btnOK() {
        this.$refs.perForm.validate().then(async () => {
          if (this.userInfoDto.userId) {
            let { data } = await updateuser(this.userInfoDto);
            if (this.userInfoDto.userId && data.code == 0) {
              this.$message.success("编辑成功");
            } else {
              console.log(data.code);
              this.$message({
                type: "error",
                message: data.code,
              });
            }
            this.getuserlist();
  
            this.showDialog = false;
          } else {
            let { data } = await adduser(this.userInfoDto);
            console.log(data);
            if (data.code == 0) {
              this.$message.success("新增成功");
            } else {
              console.log(data.msg);
              this.$message({
                type: "error",
                message: data.msg,
              });
            }
            this.getuserlist();
  
            this.showDialog = false;
          }
        });
      },
      //弹窗取消函数
      btnCancel() {
        this.userInfoDto = {}
      
        this.$refs.perForm.resetFields();
        this.showDialog = false;
      },
  
      async deluser(row) {
        try {
          await this.$confirm("确定要删除该账号吗");
          console.log(row);
          let userId = row.userId;
          let { data } = await deluser(userId);
  
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          );
          this.queryParams.pageNum =
            this.queryParams.pageNum > totalPage ? totalPage : this.queryParams.pageNum;
          this.queryParams.pageNum =
            this.queryParams.pageNum < 1 ? 1 : this.queryParams.pageNum;
  
          this.getuserlist();
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
  </script>
    
  <style scoped>
  
  </style>
    